import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';

export default function RateDropdown({
  selectedPlan,
  selectedRate,
  ratePlans,
  setSelectedRate,
}) {
  const { t } = useTranslation();
  const { ssoUser, bmoUser } = useUser();
  const { trialLength } = usePlan();
  const canTrial =
    bmoUser && bmoUser.subscriptionInfo
      ? bmoUser.subscriptionInfo.isTrialEligible
      : true;

  function getRateLabel(rate) {
    if (rate.isTrial) {
      return (
        /* prettier-ignore */
        <Trans i18nKey="trialLengthTrial">
          {{trialLength: trialLength}}-day trial
        </Trans>
      );
    }
    if (rate.isMonthly) return `${t('monthly')} ${t('subscription')}`;
    if (rate.isYearly) return `${t('yearly')} ${t('subscription')}`;
  }
  return (
    <UncontrolledDropdown className="dropdown-custom">
      <DropdownToggle color="link" className="offset-left" caret>
        {getRateLabel(selectedRate)}
      </DropdownToggle>
      <DropdownMenu>
        {ratePlans.map(
          rp =>
            ((rp.isTrial && canTrial) || !rp.isTrial) && (
              <DropdownItem
                id={rp.id}
                key={rp.id}
                tag="div"
                className="custom-control custom-radio"
                onClick={() => {
                  setSelectedRate(rp);

                  if (rp.isTrial) {
                    const postSelectNavigation = ssoUser
                      ? selectedPlan.planSelectRoutes.trial.quickSelect.loggedIn
                      : selectedPlan.planSelectRoutes.trial.quickSelect
                          .loggedOut;

                    if (postSelectNavigation) {
                      navigate(postSelectNavigation);
                    }
                  }
                }}
              >
                <input
                  readOnly
                  type="radio"
                  id="trial"
                  name="trial"
                  className="custom-control-input"
                  checked={rp.id === selectedRate.id}
                />
                <label className="custom-control-label" htmlFor="yearly">
                  {getRateLabel(rp)}
                </label>
              </DropdownItem>
            )
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
