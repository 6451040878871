import React, { useState } from 'react';
import { navigate } from 'gatsby';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { useUser } from '@util/providers/AuthProvider';

import NavigatingModal from '@util/Modals/NavigatingModal';

export default function PlanDropdown({
  plans,
  selectedPlan,
  setSelectedPlan,
  setSelectedRateByPlan,
  hideSubscriptionPlans,
}) {
  const [navigatingModal, setNavigatingModal] = useState(false);
  const [modalNavRoute, setModalNavRoute] = useState(null);
  const [modalNextPlan, setModalNextPlan] = useState(null);
  const { ssoUser } = useUser();
  function toggleNavigatingModal(plan, route) {
    //* set the plan and rate for use if the modal is confirmed
    if (plan) setModalNextPlan(plan);
    //* set the route to nav to
    if (route) setModalNavRoute(route);
    setNavigatingModal(!navigatingModal);
  }
  return (
    <>
      <UncontrolledDropdown className="dropdown-custom">
        <DropdownToggle color="link" className="offset-left" caret>
          <strong>{selectedPlan.name}</strong>
        </DropdownToggle>
        <DropdownMenu>
          {plans
            .filter(plan => plan.key)
            .map(plan => {
              const hideSubscriptionPlan =
                hideSubscriptionPlans &&
                (plan.key === 'personal' || plan.key === 'professional');
              return !plan.excludeFromPlanSummaryCard &&
                !hideSubscriptionPlan ? (
                <DropdownItem
                  key={plan.key}
                  id={plan.key}
                  tag="div"
                  className="custom-control custom-radio"
                  onClick={() => {
                    //* trigger warning modal
                    if (plan.warnOnAbortSubscription && ssoUser) {
                      toggleNavigatingModal(
                        plan,
                        plan.planSelectRoutes.quickSelect.loggedIn
                      );
                    } else {
                      //* set plan and rate
                      setSelectedPlan(plan);
                      setSelectedRateByPlan(plan);

                      //* navigate to page
                      if (plan.planSelectRoutes.quickSelect) {
                        const {
                          loggedOut: loggedOutRoute,
                          loggedIn: loggedInRoute,
                        } = plan.planSelectRoutes.quickSelect;

                        if (loggedOutRoute && !ssoUser) {
                          navigate(loggedOutRoute);
                        }
                        if (loggedInRoute && ssoUser) {
                          navigate(loggedInRoute);
                        }
                      }
                    }
                  }}
                >
                  <input
                    readOnly
                    type="radio"
                    id={plan.key}
                    name={plan.key}
                    className="custom-control-input"
                    checked={plan.key === selectedPlan.key}
                  />
                  <label className="custom-control-label" htmlFor={plan.key}>
                    {plan.name}
                  </label>
                </DropdownItem>
              ) : null;
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
      <NavigatingModal
        isOpen={navigatingModal}
        toggle={toggleNavigatingModal}
        primaryAction={() => {
          //* set plan and rate
          setSelectedPlan(modalNextPlan);
          setSelectedRateByPlan(modalNextPlan);
          navigate(modalNavRoute);
        }}
        secondaryAction={toggleNavigatingModal}
      />
    </>
  );
}
