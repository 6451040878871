import styled from 'styled-components';

const Card = styled.div.attrs({
  className: 'card',
})``;
Card.Header = styled.h4.attrs({
  className: 'card-header',
})``;
Card.Body = styled.div.attrs({
  className: 'card-body',
})``;

Card.Footer = styled.div.attrs({
  className: 'card-footer',
})``;

export default Card;
