import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlan } from '@util/providers/Plan';
import { useInvoice } from '@util/providers/Invoice';
import useSubscription from '@util/hooks/useSubscription';

import AlertError from '@util/Errors';
import SEO from '../components/seo';
import PageHeader from '@ui/PageHeader';
import ProtectedPage from '@components/ProtectedPage';
import PaymentDetailsForm from '@components/PaymentDetails/PaymentDetailsForm';
import PlanSummaryCard from '@components/PlanSummaryCard';

import Go from '@util/CustomRedirect';

const PaymentDetails = () => {
  const { t } = useTranslation();
  const {
    resetInvoicePreview,
    resetInvoiceId,
    resetInvoiceNumber,
  } = useInvoice();

  const pageTitle = t('page:paymentDetails');
  const error = undefined;

  useEffect(() => {
    //* clear out old preview invoice
    resetInvoicePreview();
    resetInvoiceId();
    resetInvoiceNumber();
  }, [resetInvoicePreview, resetInvoiceId, resetInvoiceNumber]);

  return (
    <ProtectedPage>
      <Rules>
        <SEO title={pageTitle} />

        <div className="row mb-4">
          <div className="col-12 col-xl-8">
            <PageHeader>{pageTitle}</PageHeader>
          </div>
        </div>

        {error ? (
          <AlertError heading={t('errors.loadingPlans')}>
            {error.message}
          </AlertError>
        ) : (
          <div className="row">
            <div className="col-12 col-xl-8">
              <PaymentDetailsForm />
            </div>
            <div className="col-12 order-first order-xl-last col-xl-4">
              <PlanSummaryCard />
            </div>
          </div>
        )}
      </Rules>
    </ProtectedPage>
  );
};

export default PaymentDetails;

function Rules({ children }) {
  const { selectedPlan, selectedRate } = usePlan();
  const { isSubscriber, isActiveSubscriber, isTrialUser } = useSubscription();
  const planKey = selectedPlan?.key;
  const isTrial = selectedRate?.isTrial;

  return (
    <Go
      to="/"
      when={
        !selectedPlan || !selectedRate || (isActiveSubscriber && !isTrialUser)
      }
    >
      <Go to="/trial/start" when={isTrial}>
        <Go
          to="/community/start"
          when={planKey === 'community' && !isSubscriber}
        >
          <Go
            to={process.env.ORG_REQUEST_LINK}
            when={planKey === 'organization'}
          >
            {children}
          </Go>
        </Go>
      </Go>
    </Go>
  );
}
