import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import classNames from 'classnames';

import { usePlan } from '@util/providers/Plan';
import { useYearlySavings } from '@util/Plans/PlanFunctions';

import Card from '@ui/Card';
import { TermPrice, SavingsWithCurrency } from '@ui/RateInfo';

import PlanDropdown from './PlanDropdown';
import RateDropdown from './RateDropdown';

export default function({ location }) {
  const { t } = useTranslation();
  const {
    selectedPlan,
    setSelectedPlan,
    selectedRate,
    setSelectedRate,
    setSelectedRateByPlan,
  } = usePlan();
  const { plans } = usePlan();
  const percentSavings = useYearlySavings();
  const hideSubscriptionPlans = location?.state?.hideSubscriptionPlans;
  return (
    <PlanSummaryCard>
      <PlanSummaryCard.Body className="pb-3">
        <div>
          <PlanDropdown
            plans={plans}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            setSelectedRateByPlan={setSelectedRateByPlan}
            hideSubscriptionPlans={hideSubscriptionPlans}
          />
        </div>
        <div className="d-flex justify-content-between">
          {
            //* rate dropdown or static rate
          }
          <div>
            {selectedRate.free || selectedRate.requiresQuote ? (
              <div className="py-1">{selectedRate.termName}</div>
            ) : (
              <RateDropdown
                selectedPlan={selectedPlan}
                selectedRate={selectedRate}
                ratePlans={selectedPlan.ratePlans.filter(rp => rp.active)}
                setSelectedRate={setSelectedRate}
              />
            )}
          </div>

          {
            //* price for monthly/trial/community or pre-discount price for yearly
          }
          <div
            className={classNames('d-none d-sm-block', {
              'pt-1': true,
            })}
          >
            <PriceLine1
              selectedPlan={selectedPlan}
              selectedRate={selectedRate}
            />
          </div>
        </div>

        {
          //* price for yearly rate
        }
        {selectedRate.isYearly && (
          <div className="d-flex justify-content-between">
            <div>
              {/* prettier-ignore */}
              <Trans i18nKey="save17PercentNoBold">
                Save
                {{
                  percent: percentSavings,
                }}
                %
              </Trans>
            </div>
            <div className="d-none d-sm-block">
              <strong>
                <TermPrice rate={selectedRate} />
              </strong>
            </div>
          </div>
        )}

        {
          //* mobile only
          <div className="d-block d-sm-none">
            <PriceLine1
              selectedPlan={selectedPlan}
              selectedRate={selectedRate}
            />
            {selectedRate.isYearly && (
              <strong>
                {' '}
                <TermPrice rate={selectedRate} />
              </strong>
            )}
          </div>
        }
      </PlanSummaryCard.Body>
      {//* sutotal
      !selectedRate.free && !selectedRate.isTrial && (
        <PlanSummaryCard.Footer className="d-none d-sm-block">
          <strong>
            {t('selectedPlanSummary.subtotal')}
            :&nbsp;
          </strong>
          <strong>
            <TermPrice rate={selectedRate} />
          </strong>
        </PlanSummaryCard.Footer>
      )}
    </PlanSummaryCard>
  );
}

function PriceLine1({ selectedPlan, selectedRate }) {
  const { t } = useTranslation();
  return (
    <>
      {selectedRate.isYearly && (
        <SavingsWithCurrency
          rate={selectedRate}
          plan={selectedPlan}
          className="text-gray"
        />
      )}
      {(selectedRate.isTrial || selectedRate.free) && (
        <div>
          <strong>{t('Free')}</strong>
        </div>
      )}
      {selectedRate.isMonthly && (
        <strong>
          <TermPrice rate={selectedRate} />
        </strong>
      )}
    </>
  );
}

const PlanSummaryCard = styled(Card)``;

PlanSummaryCard.Body = styled(Card.Body)`
  //   line-height: 1.75em;
`;

PlanSummaryCard.Footer = styled(Card.Footer)`
  text-align: right;
`;
