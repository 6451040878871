import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function NavigatingModal({
  isOpen,
  toggle,
  primaryAction,
  secondaryAction,
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader
      //   toggle={toggle} charCode=""
      >
        {t('account.cancelSubscription')}
        <button
          type="button"
          className="close"
          id="NavigatingModalClose"
          onClick={toggle}
          aria-label="Close"
        >
          <span aria-hidden="true" />
        </button>
      </ModalHeader>
      <ModalBody>
        {t('account.areYouSure')}
        <ul className="pt-2">
          <li>{t('account.cancelSubscriptionProcess')}</li>
          <li>{t('account.changeToCommunityPlan')}</li>
        </ul>
        {t('account.onceYouveCompletedYourProfile')}
      </ModalBody>
      <ModalFooter className="pb-5">
        <button
          className="btn btn-primary"
          id="NavigatingModalProceed"
          onClick={primaryAction}
        >
          {t('account.proceed')}
        </button>
        <button
          className="btn btn-outline-secondary"
          id="NavigatingModalBack"
          onClick={() => {
            toggle();
            secondaryAction();
          }}
        >
          {t('account.back')}
        </button>
      </ModalFooter>
    </Modal>
  );
}
